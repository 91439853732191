import React from 'react';
import styled from '@emotion/styled';
import Wrapper from '../components/layout/Wrapper';
import App from '../components/layout/App';
import { Color, FontWeight, FontSize } from '../enums/appStyles';
import Cta from './../components/base/Cta';
import { Route } from './../enums/app';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10rem 0;
  min-height: 100vh;
  background: ${Color.NeutralDarkest};
  color: ${Color.Light};
`;

const Legend = styled.p`
  font-size: ${FontSize.XS};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: ${FontWeight.Light};
  color: ${Color.Clr1};
`;

const Title = styled.h1`
  font-size: ${FontSize.XXL};
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  margin: 0.9rem 0 0.6rem;
`;

const Subtitle = styled.p`
  margin-bottom: 1.6rem;
`;

const NotFoundPage = () => {
  return (
    <App>
      <Container>
        <Wrapper>
          <Legend>Allo Houston</Legend>
          <Title>On a eu un problème&nbsp;!</Title>
          <Subtitle>Désolé la page que vous cherchez n'existe plus</Subtitle>
          <div css={{ marginTop: '4.8rem' }}>
            <Cta to={Route.Home}>Retour à l'accueil</Cta>
          </div>
        </Wrapper>
      </Container>
    </App>
  );
};
export default NotFoundPage;
